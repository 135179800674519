import { useCallback } from "react";
import { getProDataForPodcastPromise } from "routines/podcast";

import {
  selectProDataForPodcast,
  selectProDataLoading,
  selectProDataFailed,
} from "selectors/podcast";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import useLoadEntity from "hooks/useLoadEntity";
import useRoutinePromises from "hooks/useRoutinePromises";

function useProDataForPodcast(podcastId) {
  const { getProDataForPodcast } = useRoutinePromises({
    getProDataForPodcast: getProDataForPodcastPromise,
  });

  const userHasPro = useHasProForPodcast(podcastId);

  const loader = useCallback(() => {
    getProDataForPodcast({ podcastId });
  }, [getProDataForPodcast, podcastId]);

  return useLoadEntity({
    entity_type: "pro_data",
    entity_id: podcastId,
    loader,
    dontLoad: !userHasPro,
    selector: selectProDataForPodcast,
    loadingSelector: selectProDataLoading,
    failedSelector: selectProDataFailed,
  });
}

export default useProDataForPodcast;
