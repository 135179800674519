import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faBriefcase } from "@fortawesome/pro-regular-svg-icons/faBriefcase";
import { faBullhorn } from "@fortawesome/pro-regular-svg-icons/faBullhorn";
import { faFax } from "@fortawesome/pro-regular-svg-icons/faFax";
import { faMicrophoneAlt } from "@fortawesome/pro-regular-svg-icons/faMicrophoneAlt";
import { faUserTie } from "@fortawesome/pro-regular-svg-icons/faUserTie";
import { List } from "immutable";

export const getContactIcon = (contact) => {
  if (!contact) {
    return faEnvelope;
  }

  const role = contact.get("role") ? contact.get("role").toLowerCase() : "";
  const title = contact.get("title") ? contact.get("title").toLowerCase() : "";

  const valueToCheck = role || title;

  switch (valueToCheck) {
    case "podcast email":
      return faMicrophoneAlt;
    case "marketing":
      return faBullhorn;
    case "hr":
      return faBriefcase;
    default:
      break;
  }
  if (valueToCheck.includes("producer")) {
    return faUserTie;
  }
  if (valueToCheck.includes("support")) {
    return faFax;
  }
  return faEnvelope;
};

export const itemsFromContactData = (contacts) =>
  contacts?.map((contact) => {
    if (contact) {
      const fullName =
        contact.get("full_name") && contact.get("full_name").trim();
      const subLabel = fullName && fullName !== "-" ? fullName : null;
      const showRss = contact.get("title") === "Podcast Email";
      const item = {
        key: contact.get("email"),
        icon: getContactIcon(contact),
        label: contact.get("role") || contact.get("title") || null,
        department: contact.get("department"),
        socialAccounts: contact.get("social_accounts"),
        confidence: contact.get("confidence"),
        id: contact.get("id"),
        subLabel,
        type: contact.get("type"),
        company: contact.get("company_name"),
        companyTypes: contact.get("services"),
        value: contact.get("email"),
        url: contact.get("url"),
        contactableId: contact.get("contactable_id"),
        contactableType: contact.get("contactable_type"),
        voteScore: contact.get("vote_score"),
        voteable: contact.get("voteable"),
        userVote: contact.get("user_vote"),
        source: contact.get("source"),
        flagged: contact.get("flagged"),
        flaggable: contact.get("flaggable"),
      };

      if (showRss) {
        item.showRss = true;
      }

      return item;
    }

    return contact;
  });

export const sortContacts = (section) => {
  const sortedItems = section?.items.reduce((items, item) => {
    if (item.label === "Podcast Email") {
      return [item, ...items];
    }
    return [...items, item];
  }, []);

  return sortedItems;
};

export const getContactsForms = (proData) => {
  let contacts = List();

  if (proData?.has("contacts") && proData?.get("contacts").size > 0) {
    contacts = itemsFromContactData(proData?.get("contacts"));
  }

  return contacts.filter(
    (contact) => contact.url || contact.label === "Contact Page"
  );
};

export const formatContacts = (contacts) => {
  const contactsWithContext = contacts?.filter((contact) => !!contact.label);
  const contactsWithoutContext = contacts?.filter((contact) => !contact.label);

  const combinedContacts = [
    ...(contactsWithContext || []),
    ...(contactsWithoutContext || []),
  ].filter((i) => !!i);

  if (combinedContacts) {
    return [
      {
        key: "combined",
        title: null,
        items: combinedContacts,
      },
    ];
  }
  return null;
};

export const getFormattedContacts = (proData, unformatted) => {
  let contacts = List();

  if (proData?.has("contacts") && proData?.get("contacts").size > 0) {
    contacts = itemsFromContactData(proData?.get("contacts"));
  }

  if (unformatted) {
    return [
      {
        key: "combined",
        title: null,
        items: [...contacts],
      },
    ];
  }

  return formatContacts(contacts);
};

export const getFormattedContactsForAgenciesOfRecords = (proData) => {
  const contacts = itemsFromContactData(proData);

  return formatContacts(contacts);
};

export const getClickableLink = (link, entityId) => {
  const linkWithProtocol =
    link?.startsWith("http://") || link?.startsWith("https://")
      ? link
      : `http://${link}`;

  const url = new URL(linkWithProtocol);

  url.searchParams.append("utm_medium", "referral");
  url.searchParams.append("utm_source", "podchaser");
  url.searchParams.append("utm_content", entityId);

  return url;
};
