import { useCallback, useState } from "react";

export default function useClipboardCopy() {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(async (text, onClick) => {
    if (
      (typeof text === "string" || typeof text == "number") &&
      "clipboard" in navigator
    ) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);

      if (onClick) {
        onClick(text);
      }

      return await navigator.clipboard.writeText(text);
    } else {
      setCopied(false);
      console.error(
        `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
      );
    }
  }, []);

  return [handleCopy, copied];
}
