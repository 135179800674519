import React, { useCallback } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    marginTop: "3rem",
  },
  search: {
    ...gStyles.fontMedium,
    fontSize: "0.75rem",
    borderRadius: 4,
    border: `1px solid ${colours.borderGrey}`,
    color: colours.bodyText,
    padding: "0 0.313rem",
    outline: "none",
    height: "2rem",

    ":focus": {
      border: `1px solid ${colours.secondary}`,
    },
  },
};

export default function ContactSearch(props) {
  const { css, styles } = useStyles(baseStyles, props);

  const { setValue, value } = props;

  const handleSearch = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <input
      value={value}
      onChange={handleSearch}
      data-testid="search"
      type="text"
      className={css(styles.search)}
      placeholder="Search"
    />
  );
}
