import PropTypes from "prop-types";
import React, { useCallback } from "react";

import EntityTab from "components/Lists/EntityTab";

import ContactSearch from "./ContactsSearch";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  tabsContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonTab: {
    outline: "none",
    border: "none",
    padding: "0 0.5rem 0 0",
  },
  firstContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "1rem",
    alignItems: "center",
    marginBottom: "1rem",
    padding: "0 1rem",

    [ScreenSizes.mdAndAbove]: {
      padding: "0",
      gridTemplateColumns: "1fr max-content",
      gridGap: "1rem",
    },
  },
};

export const Tabs = (props) => {
  const { items, activeTab, handleTabClick } = props;
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.tabsContainer)}>
      {items?.map((tab) => {
        const { label, id, icon, condition } = tab;

        if (condition === true) {
          return null;
        }

        return (
          <button
            className={css(styles.buttonTab)}
            key={id}
            onClick={() => handleTabClick && handleTabClick(id)}
          >
            <EntityTab icon={icon} name={label} isActive={activeTab === id} />
          </button>
        );
      })}
    </div>
  );
};

export default function ContactsHeaderTools(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const {
    items,
    setValue,
    activeTab,
    searchValue,
    setActiveTab,
    entityType,
    entityId,
    hideSearch,
    eventProps,
  } = props;

  const handleTabClick = useCallback(
    (selectedTab) => {
      if (activeTab !== selectedTab) {
        sendGAEvent({
          action: "contactsTabChanged",
          newTab: selectedTab,
          entityType,
          entityId,
          ...eventProps,
        });
        setActiveTab(selectedTab);
      }
    },
    [activeTab, entityId, eventProps, entityType, setActiveTab]
  );

  return (
    <div className={css(styles.firstContainer)}>
      {activeTab ? (
        <Tabs
          handleTabClick={handleTabClick}
          items={items}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ) : (
        <div />
      )}
      {!hideSearch && <ContactSearch setValue={setValue} value={searchValue} />}
    </div>
  );
}

ContactsHeaderTools.propTypes = {
  items: PropTypes.array,
  setValue: PropTypes.func,
  activeTab: PropTypes.string,
  searchValue: PropTypes.string,
  setActiveTab: PropTypes.func,
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  hideSearch: PropTypes.bool,
  eventProps: PropTypes.object,
};

ContactsHeaderTools.defaultProps = {
  items: [],
  setValue: null,
  activeTab: "",
  searchValue: "",
  setActiveTab: null,
  entityType: "",
  entityId: "",
  hideSearch: false,
  eventProps: {},
};
