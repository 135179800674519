import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ContactTable = Loadable(() => import("./ContactTableContainer"));

function ContactTableContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const ContactTableContainer = (props) => {
  return (
    <ContactTable {...props} fallback={<ContactTableContainerAsyncLoading />} />
  );
};

export default ContactTableContainer;
