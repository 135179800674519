import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  section: {
    marginBottom: "3rem",
  },
  row: {
    ...gStyles.skeletonPlaceholder,
    padding: "1rem",
    minHeight: 79,
  },
};

export default function LoadingSkeleton(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.section)}>
      <div className={css(styles.row)} />

      <div className={css(styles.row)} />

      <div className={css(styles.row)} />

      <div className={css(styles.row)} />

      <div className={css(styles.row)} />

      <div className={css(styles.row)} />
    </div>
  );
}
