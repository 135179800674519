import { Map } from "immutable";
import { createSelector } from "reselect";

export const selectContacts = (state) =>
  state.contact ? state.contact.getIn(["contacts", "entities"]) : Map();

const selectSpecificContactMemoized = createSelector(
  [selectContacts, (_, id) => id],
  (contacts, id) => contacts.get(id?.toString())
);

export const makeSelectSpecificContact = () => selectSpecificContactMemoized;
