import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import InsightsContactItemRow from "./InsightsContactItemRow";

import getPodcastName from "utils/entity/getPodcastName";
import existsKeyOnArray from "utils/existsKeyOnArray";
import sendGAEvent from "utils/sendGAEvent";

import useClipboardCopy from "hooks/useClipboardCopy";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  noSearchResult: {
    ...gStyles.avalonBold,
    display: "grid",
    placeItems: "center",
    fontSize: "0.875rem",
    textAlign: "center",
    margin: "2rem",
  },
};

const InsightsContactItem = (props) => {
  const {
    podcast,
    items,
    maxItems,
    searchValue,
    compact,
    showCompany,
    showVotes,
  } = props;
  const { css, styles } = useStyles(baseStyles, props);
  const [handleCopy, copied] = useClipboardCopy();

  const handleEventTracking = useCallback(
    (value) => {
      sendGAEvent({
        action: "contactCopied",
        contact: value,
        podcast_id: podcast && podcast.get("id"),
        podcast_name: podcast && getPodcastName(podcast),
      });
    },
    [podcast]
  );

  const handleLinkClick = useCallback(
    (email) => {
      sendGAEvent({
        action: "linkClick",
        context: "PodcastInsightsPage",
        email,
        podcast_id: podcast && podcast.get("id"),
        podcast_name: podcast && getPodcastName(podcast),
      });
    },
    [podcast]
  );

  const content = items.map((item, index) => {
    const { label, subLabel, value, url, contactableId } = item;

    const searchGroups = [label, subLabel, value, url];
    let canBeShownInSearch = searchValue?.trim() === "";

    if (searchValue?.trim() !== "") {
      canBeShownInSearch =
        existsKeyOnArray && existsKeyOnArray(searchGroups, searchValue);
    }

    if (!canBeShownInSearch) {
      return null;
    }

    if (index < maxItems || searchValue?.trim()) {
      return (
        <InsightsContactItemRow
          key={contactableId}
          compact={compact}
          copied={copied}
          handleCopy={handleCopy}
          handleEventTracking={handleEventTracking}
          handleLinkClick={handleLinkClick}
          podcastId={podcast?.get("id")}
          lastContact={index === maxItems - 1}
          podcastOwner={item.source === "podcast_owner"}
          showCompany={showCompany}
          showVotes={showVotes}
          styles={props?.styles}
          {...item}
        />
      );
    }

    return null;
  });

  if (!content?.filter((item) => item !== null)?.length) {
    return <p className={css(styles.noSearchResult)}>No Results Found</p>;
  }

  return <div>{content}</div>;
};

InsightsContactItem.propTypes = {
  items: PropTypes.array.isRequired,
  podcast: PropTypes.instanceOf(Map),
  maxItems: PropTypes.number,
  showCompany: PropTypes.bool,
};

InsightsContactItem.defaultProps = {
  items: null,
  maxItems: 5,
  showCompany: false,
};

export default InsightsContactItem;
