import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { faUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faUpRightFromSquare";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import Title from "components/Entities/Page/Title";

import { insightStyles, secondaryTitleStyles } from "../../InsightsStyles";
import acastLogo from "../image/acastLogo.png";
import ReachContentHelpIcon from "../insightReach/ReachContentHelpIcon";
import ReachPopUp from "../insightReach/ReachPopUp";
import Contacts from "./Contacts";
import ContactTableContainer from "./ContactTableContainerAsync";

import {
  getContactsForms,
  getClickableLink,
} from "utils/contacts/getFormattedContacts";
import sendGAEvent from "utils/sendGAEvent";

import useIsBatmanMode from "hooks/useIsBatmanMode";
import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const ACAST_PROVIDER = "Acast";

const baseStyles = {
  insightsSection: insightStyles.insightsSection,
  createContactLink: {
    ...gStyles.avalonMedium,
    fontSize: "0.875rem",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: 8,
    padding: "0.313rem 0.625rem",
    width: "max-content",
    display: "flex",
    gap: "0.625rem",
    alignItems: "Center",
    marginLeft: "auto",
    "--fa-secondary-opacity": 1,
    "--fa-primary-color": colours.bodyText,
    "--fa-secondary-color": colours.bodyText,

    ":hover": {
      cursor: "pointer",
    },
  },
  formsContainer: {
    position: "relative",
  },
  formsModal: {
    position: "absolute",
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    right: 0,
    background: colours.white,
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.14)",
    marginTop: "-0.188rem",
    zIndex: 1,
  },
  contactLink: {
    position: "Relative",
    display: "flex",
    gap: "0.5rem",
    flexDirection: "column",
    padding: "1.2rem 1.5rem 0.875rem",
    borderBottom: `1px solid ${colours.borderGrey}`,

    ":hover": {
      cursor: "pointer",
      background: "rgba(250, 250, 250, 0.7)",
    },
  },
  noborder: {
    borderBottom: `none`,
  },
  contactLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    margin: 0,
  },
  contactSubLabel: {
    ...gStyles.avalonLight,
    fontSize: "0.875rem",
    margin: 0,
    maxWidth: "250px",
    [ScreenSizes.lgAndAbove]: {
      maxWidth: "max-content",
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  openIcon: {
    "--fa-secondary-opacity": 1,
    "--fa-primary-color": colours.bodyText,
    "--fa-secondary-color": colours.bodyText,
    position: "absolute",
    right: 10,
    top: 10,
    fontSize: "0.75rem",
  },
  acastLink: {
    color: colours.white,
    margin: "0 0.2rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  acastAd: {
    background: colours.bodyText,
    borderRadius: 8,
    padding: "0.5rem 1rem",
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gridGap: "1rem",
    placeItems: "center",
    margin: "1rem 1rem 0 1rem",

    [ScreenSizes.mdAndAbove]: {
      gridTemplateColumns: "max-content 1fr max-content",
    },

    [ScreenSizes.lgAndAbove]: {
      margin: "1rem 0 0 0",
      marginTop: "1rem",
    },
  },
  acastLogo: {
    margin: 0,
    height: "0.9rem",

    [ScreenSizes.mdAndAbove]: {
      height: "1rem",
    },
  },
  acastDetails: {
    margin: 0,
    fontSize: "0.813rem",
    ...gStyles.avalonBold,
    color: colours.newYellow,
  },
  acastButton: {
    ...gStyles.avalonMedium,
    gap: "0.313rem",
    alignItems: "center",
    background: colours.newYellow,
    borderRadius: "1.563rem",
    border: "none",
    fontSize: "0.813rem",
    padding: "0.313rem 1rem",
    display: "none",

    [ScreenSizes.mdAndAbove]: {
      display: "flex",
    },

    ":hover": {
      cursor: "pointer",
    },
  },
  subtitleIcon: {
    color: "#999",
    fontSize: "1rem",
    cursor: "pointer",
  },
  info: {
    display: "flex",
    alignItems: "center",
    gap: "0.625rem",
  },
  data: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.fontBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  titleContainer: {
    marginRight: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginRight: 0,
    },
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "1rem auto 0 auto",
    cursor: "pointer",
  },
};

const popUpStyles = {
  outer: {
    width: "100%",
    [ScreenSizes.mdAndAbove]: {
      marginTop: 0,
      bottom: 30,
      left: -120,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const InsightsContacts = (props) => {
  const {
    proData,
    podcast,
    passedRef,
    compact,
    showCompany,
    showVotes,
    showHeaderTools,
    showLoadMore,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isBatmanMode } = useIsBatmanMode();
  const [showContactForms, setShowContactForms] = useState(false);

  const [showPopUp, setShowPopUp] = useState({ active: null });

  const handlePopUp = useCallback((active) => {
    setShowPopUp({ active });
  }, []);
  const activelySelected = showPopUp?.active === "contacts";

  const { getWindowWidth } = useWindowSize();

  const currentSize = getWindowWidth();
  const onMobile = currentSize < 640;

  const onClick = useCallback(() => {
    if (activelySelected) {
      handlePopUp(null);
      sendGAEvent({
        action: "Podcast Contact Info Icon Clicked Close Popup",
        podcast_id: podcast.get("id"),
        podcast_name: podcast.get("title"),
        context: "Podcast Insights",
      });
    } else {
      handlePopUp("contacts");
      sendGAEvent({
        action: "Podcast Contact Info Icon Clicked Open Popup",
        podcast_id: podcast.get("id"),
        podcast_name: podcast.get("title"),
        context: "Podcast Insights",
      });
    }
  }, [activelySelected, handlePopUp, podcast]);

  const hostProvider = podcast?.get("hosting_provider");
  const showAcastCTA = hostProvider === ACAST_PROVIDER;

  const contactForms = getContactsForms(proData);

  const toggleShowContactForms = useCallback(() => {
    setShowContactForms(!showContactForms);
  }, [setShowContactForms, showContactForms]);
  const wrapperRef = useOutsideClickHandler(() => handlePopUp(null));

  const renderFormLabel = useCallback(() => {
    if (contactForms?.size <= 1) {
      const link = contactForms?.first()?.url || contactForms?.first()?.value;

      return (
        <a
          className={css(styles.createContactLink)}
          href={getClickableLink(link, podcast?.get("id"))}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="singleformAnchor"
        >
          Open Contact Form
          <FontAwesomeIcon icon={faUpRightFromSquare} dataid="contactIcon" />
        </a>
      );
    } else {
      return (
        <div className={css(styles.formsContainer)}>
          <span
            className={css(styles.createContactLink)}
            onClick={toggleShowContactForms}
          >
            Contact Forms
            <FontAwesomeIcon
              icon={showContactForms ? faCaretUp : faCaretDown}
              dataid="multiContactIcon"
            />
          </span>
          {showContactForms && (
            <div className={css(styles.formsModal)}>
              {contactForms.map((form, index) => {
                const link = form?.url || form?.value;

                return (
                  <a
                    key={form?.key}
                    className={css(
                      styles.contactLink,
                      index === contactForms.size - 1 && styles.noborder
                    )}
                    href={getClickableLink(link, podcast?.get("id"))}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={`multiFormAnchor-${index}`}
                  >
                    <p className={css(styles.contactLabel)}>{form?.label}</p>
                    <p className={css(styles.contactSubLabel)}>
                      {form?.url || form?.value}
                    </p>
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className={css(styles.openIcon)}
                    />
                  </a>
                );
              })}
            </div>
          )}
        </div>
      );
    }
  }, [contactForms, showContactForms]);

  const showcontactLinkButton = contactForms?.size > 0;

  const handleAcastButtonClick = useCallback(
    () =>
      sendGAEvent({
        action: "BuyAcastAdCTAClicked",
        page: "Podcast Insight",
      }),
    []
  );

  const renderAcastAd = useCallback(() => {
    return (
      <div className={css(styles.acastAd)} data-testid="acastAd">
        <img
          className={css(styles.acastLogo)}
          src={acastLogo}
          alt="acastlogo"
          data-testid="acastLogo"
        />
        <h5 className={css(styles.acastDetails)} data-testid="acastAdLabel">
          You can
          <a
            href="https://apply.selfserve.acast.com/apply?utm_medium=referral&utm_source=podchaser"
            target="_blank"
            rel="noopener noreferrer"
            className={css(styles.acastLink)}
            onClick={handleAcastButtonClick}
            data-testid="ctaLink"
          >
            buy ads directly
          </a>
          across all Acast podcasts
        </h5>
        <a
          href="https://apply.selfserve.acast.com/apply?utm_medium=referral&utm_source=podchaser"
          target="_blank"
          rel="noreferrer"
          className={css(styles.acastButton)}
          onClick={handleAcastButtonClick}
        >
          Learn More
        </a>
      </div>
    );
  }, [
    handleAcastButtonClick,
    styles.acastAd,
    styles.acastButton,
    styles.acastDetails,
    styles.acastLink,
    styles.acastLogo,
  ]);

  const inlineTitleContent = () => {
    return (
      <div className={css(styles.info)}>
        <div style={{ position: "relative" }}>
          <ReachContentHelpIcon onClick={onClick} show={activelySelected}>
            {activelySelected && (
              <ReachPopUp styles={popUpStyles}>
                <h2
                  data-testid="popup-header"
                  className={css(styles.popUpTitle)}
                >
                  Contacts
                </h2>
                <p className={css(styles.para)}>
                  Podcasts are regularly updated with validated contact emails
                  and forms when available. Add your vote to help identify
                  contacts that are most relevant.
                </p>
                <>
                  {onMobile ? (
                    <a
                      className={css(styles.paraBold)}
                      href="https://support.podchaser.com/en/articles/8601082-podchaser-contacts"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more &nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        dataid="expand"
                      />
                    </a>
                  ) : (
                    <p className={css(styles.para)}>
                      Learn more about
                      <a
                        ref={wrapperRef}
                        className={css(styles.link)}
                        href="https://support.podchaser.com/en/articles/8601082-podchaser-contacts"
                        target="_blank"
                        rel="noreferrer"
                      >
                        podcast contacts.
                      </a>
                    </p>
                  )}
                </>
                {onMobile && (
                  <button onClick={onClick} className={css(styles.button)}>
                    Got It
                  </button>
                )}
              </ReachPopUp>
            )}
          </ReachContentHelpIcon>
        </div>
        {showcontactLinkButton && renderFormLabel()}
      </div>
    );
  };

  return (
    <section
      ref={passedRef}
      className={css(styles.insightsSection)}
      id="podcast-contacts"
    >
      {!compact && (
        <div className={css(styles.titleContainer)}>
          <Title
            title="Contacts"
            TitleComponent="h2"
            styles={secondaryTitleStyles}
            noTopPadding
            inlineContent={inlineTitleContent()}
          />
        </div>
      )}
      {compact || !isBatmanMode ? (
        <Contacts
          proData={proData}
          podcast={podcast}
          compact={compact}
          showCompany={showCompany}
          showVotes={showVotes}
          showHeaderTools={showHeaderTools}
          showLoadMore={showLoadMore}
          styles={props?.styles}
        />
      ) : (
        <ContactTableContainer
          proData={proData}
          podcast={podcast}
          showHeaderTools={showHeaderTools}
          showLoadMore={showLoadMore}
          styles={props?.styles}
        />
      )}
      {!compact && showAcastCTA && renderAcastAd()}
    </section>
  );
};

InsightsContacts.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
  passedRef: PropTypes.object,
  compact: PropTypes.bool,
  showCompany: PropTypes.bool,
  showHeaderTools: PropTypes.bool,
  showLoadMore: PropTypes.bool,
};

InsightsContacts.defaultProps = {
  podcast: null,
  passedRef: null,
  proData: Map(),
  compact: false,
  showCompany: false,
  showHeaderTools: false,
  showLoadMore: false,
};

export default InsightsContacts;
