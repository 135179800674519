import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  tab: {
    display: "grid",
    placeItems: "center",
    ...gStyles.avalonBold,
    padding: "0.4rem 1.125rem 0.5rem 1.125rem",
    background: colours.lightGreyBorder,
    color: colours.bodyText,
    fontSize: "1rem",
    borderRadius: "1.563rem",
    maxHeight: "2rem",

    ":hover": {
      cursor: "pointer",
      background: "rgba(0, 0, 0, 0.2)",
    },
  },
  activeTab: {
    background: colours.secondaryPurple,
    color: colours.white,

    ":hover": {
      cursor: "pointer",
      background: colours.secondaryPurple,
    },
  },
  tabWithIcon: {
    display: "flex",
    gap: "3px",
    fontSize: "0.75rem",

    [ScreenSizes.mdAndAbove]: {
      fontSize: "0.813rem",
    },

    [ScreenSizes.xlAndAbove]: {
      fontSize: "1rem",
    },
  },
  loadingTab: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: "1.563rem",
    maxHeight: "2rem",
  },
  disabled: {
    color: "#bababa",
    ":hover": {
      cursor: "default",
      background: colours.lightGreyBorder,
    },
  },
  icon: {
    marginRight: "0.125rem",
  },
};

export default function EntityTab(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { name, icon, isActive, loading, disabled } = props;

  return (
    <span
      className={css(
        styles.tab,
        isActive && styles.activeTab,
        icon && styles.tabWithIcon,
        loading && styles.loadingTab,
        disabled && styles.disabled
      )}
    >
      {icon && (
        <span className={css(styles.icon)}>
          <FontAwesomeIcon icon={icon} />{" "}
        </span>
      )}
      {name}
    </span>
  );
}

EntityTab.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

EntityTab.defaultProps = {
  name: null,
  icon: null,
  isActive: null,
  loading: null,
  disabled: null,
};
