import { contactSchema } from "api/schema/contacts";
import { normalize } from "normalizr";
import { CANCEL } from "redux-saga";

import client from "./client";

export function loadContactsFilterList({
  sort_order,
  sort_direction,
  offset,
  count,
  filters,
  suggestions,
  rescore,
  cancelToken,
  options,
  omit_results,
  total_hits,
}) {
  const request = client
    .post(
      "/list/contact",
      {
        start: offset,
        count,
        sort_order,
        sort_direction,
        filters,
        suggestions,
        rescore,
        options,
        omit_results,
        total_hits,
      },
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        { contacts: entities, ...additional_entities },
        {
          contacts: [contactSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.contacts,
        ...rest,
        meta: { ...data },
      };
    });

  request[CANCEL] = () => cancelToken.cancel();

  return request;
}
